  <template lang='pug'>
tbody.automatic-notification-list-item
  tr
    td: router-link(:to = '{ name: "AutomaticNotification", params: { id: notification.id } }') {{ notification.id }}
    td {{ getTemplateById(notification.templateId).templateName }}
    td {{ feeType }}
    td {{ classifierById('productNotificationTypes', notification.typeId).human }}
    td(:class = 'triggerClass') {{ notificationTriggerType.human }}
    td {{ notification.triggerDays }}
    td
      fi-switch(:value = 'notification.active'
                :class ='{ "switch-success": notification.active }'
                disabled)
    td.text-center
      .btn-group
        router-link.btn.btn-link.px-1.btn-sm(:to = '{ name: "AutomaticNotification", params: { id: notification.id } }')
          i.far.fa-edit.fa-fw.fa-lg
        button.btn.btn-link.text-danger.px-1.btn-sm(v-fi-confirm = '{ ok: deleteNotification, message: $t("deleteConfirm")}')
          i.fa.fa-spinner.fa-pulse.fa-lg.fa-fw(v-if = 'deleting')
          i.far.fa-trash-alt.fa-fw.fa-lg(v-else)
  tr
    td(colspan = '8')
      strong {{ $t('products') }}
      span.badge.badge-pill.badge-secondary.m-2(v-for = 'product in notificationProducts'
                                                :key  = 'product.id') {{ product.name }}
</template>


<script>
import FiSwitch from '@/components/FiSwitch'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'automatic-notification-list-item',
  components: { FiSwitch },
  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName']),
    ...mapGetters('templates', ['getTemplateById']),
    ...mapState('products', ['productsList']),
    deleting () {
      return this.$vueLoading.isLoading(`notification:automatic:${this.notification.id}:delete`)
    },
    notificationTriggerType () {
      return this.classifierById('automaticNotificationTriggerType', this.notification.triggerId)
    },
    triggerClass () {
      return {
        'text-danger': this.notificationTriggerType.name === 'IN_DEBT'
      }
    },
    feeType () {
      const { feeType = {} } = this.notification
      return this.classifierByName(feeType.classifier, feeType.code)?.human
    },
    notificationProducts () {
      return this.productsList.filter(({ id }) => this.notification.productIds.includes(id))
    }
  },

  methods: {
    ...mapActions('notifications', ['deleteAutomaticNotification']),
    async deleteNotification ({ done }) {
      await this.deleteAutomaticNotification({ notificationId: this.notification.id })
      done()
    }
  }
}
</script>


<i18n>
en:
  products:      "Products:"
  deleteConfirm: "Are you sure want to delete this notification?"
et:
  products:      "Products:"
  deleteConfirm: "Are you sure want to delete this notification?"
ru:
  products:      "Products:"
  deleteConfirm: "Are you sure want to delete this notification?"
</i18n>
