<template lang='pug'>
.automatic-notification-list.animated.fadeIn
  .card
    .card-header
      | {{ $t('title') }}
      router-link.float-right(to = 'new'
                              append) {{ $t('newLink') }}
    .card-body
      .table-responsive
        table.table.table-hover
          thead: tr
            th ID
            th {{ $t('template') }}
            th {{ $t('serviceFee') }}
            th {{ $t('channel') }}
            th {{ $t('trigger') }}
            th {{ $t('days') }}
            th {{ $t('active') }}
            th
          tr(v-if = '$vueLoading.isLoading("notifications:automatic:fetch")'): td.text-center(colspan = 8): i.fa.fa-spinner.fa-pulse.fa-lg
          tr(v-else-if = 'automaticNotifications.length === 0'): td.text-center(colspan = 8) {{ $t('notFound') }}
          template(v-else)
            automatic-notification-list-item(v-for         = 'notification in sortClassifiers'
                                             :key          = 'notification.id'
                                             :notification = 'notification')
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AutomaticNotificationListItem from '@/views/admin/automaticNotifications/AutomaticNotificationListItem'

export default {
  name: 'automatic-notification-list',

  components: { AutomaticNotificationListItem },

  i18nOptions: {},

  computed: {
    ...mapState('notifications', ['automaticNotifications']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapGetters('templates', ['getTemplateById']),
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.automaticNotifications))
      list.forEach((current) => {
        current.templateName = this.getTemplateById(current.templateId).templateName
      })
      const sortList = list.sort(function (a, b) {
        const nameA = a.templateName
        const nameB = b.templateName
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    }
  },

  created () {
    this.loadAutomaticNotifications()
    this.loadProducts({ group: this.optionsFromClassifier('productGroups', true).map(({ value }) => value) })
  },

  methods: {
    ...mapActions('notifications', ['loadAutomaticNotifications']),
    ...mapActions('products', ['loadProducts'])
  }
}
</script>


<i18n>
en:
  title:      "Automatic notifications"
  newLink:    "+ New automatic notification"
  template:   "Template"
  serviceFee: "Service fee"
  channel:    "Channel"
  trigger:    "Trigger"
  days:       "Days"
  active:     "Active"
  notFound:   "No automatic notifications"
et:
  title:      "Automatic notifications"
  newLink:    "+ New automatic notification"
  template:   "Template"
  serviceFee: "Service fee"
  channel:    "Channel"
  trigger:    "Trigger"
  days:       "Days"
  active:     "Active"
  notFound:   "No automatic notifications"
ru:
  title:      "Automatic notifications"
  newLink:    "+ New automatic notification"
  template:   "Template"
  serviceFee: "Service fee"
  channel:    "Channel"
  trigger:    "Trigger"
  days:       "Days"
  active:     "Active"
  notFound:   "No automatic notifications"
</i18n>
