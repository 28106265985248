<template lang = 'pug'>
.automatic-notification.animated.fadeIn
  v-loading(:loader = 'loading')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    form.form-horizontal.animated.fadeIn(
      novalidate
      @submit.prevent = 'onSubmit'
    )
      .card
        .card-header {{ header }}
        .card-body
          .row
            .col-lg
              fi-form-field(
                :label      = '$t("active")'
                :label-cols = 3
              )
                fi-switch(v-model = 'notificationData.active')

              fi-form-field(
                :label      = '$t("trigger")'
                :label-cols = 3
                :state      = '!$v.notificationData.triggerId.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.notificationData.triggerId.required') {{ $t('common:required') }}
                fi-select(
                  v-model.number = 'notificationData.triggerId'
                  :options       = 'optionsFromClassifier("automaticNotificationTriggerType")'
                  :class         = '{ "is-invalid": $v.notificationData.triggerId.$error }'
                  required
                  sm
                )

              fi-form-field(
                :label      = '$t("template")'
                :label-cols = 3
                :state      = '!$v.notificationData.templateId.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.notificationData.templateId.required') {{ $t('common:required') }}
                fi-select(
                  v-model.number = 'notificationData.templateId'
                  :options       = 'optionsFromTemplates(["NOTIFICATION_EMAIL_TEMPLATE", "DEPOSIT_NOTIFICATION_EMAIL_TEMPLATE"])'
                  :class         = '{ "is-invalid": $v.notificationData.templateId.$error }'
                  required
                  sm
                )

              fi-form-field(
                :label      = 'true'
                :label-cols = 3
                :state      = '!$v.notificationData.triggerDays.$error'
                :disabled   = 'isDaysDisabled'
              )
                template(#label)
                  | {{$t("days")}}
                  template(v-if = 'isDaysAfter || isDaysBefore')
                    span.fa-stack.fa-sm.text-info(id = 'toggle_notice')
                      i.fas.fa-exclamation.fa-fw.fa-stack-1x
                      i.far.fa-circle.fa-fw.fa-stack-2x
                    b-popover(
                      target    = 'toggle_notice'
                      triggers  = 'hover'
                      placement = 'bottom'
                    ) {{ isDaysAfter ? $t('daysAfterNotice') : $t('daysBeforeNotice') }}
                template(#error)
                  .error-message(v-if = '!$v.notificationData.triggerDays.required') {{ $t('common:required') }}
                input.form-control.form-control-sm(
                  v-model.number = 'notificationData.triggerDays'
                  :class         = '{ "is-invalid": $v.notificationData.triggerDays.$error }'
                )

              fi-form-field(
                :label      = '$t("channel")'
                :label-cols = 3
                :state      = '!$v.notificationData.typeId.$error'
                :disabled   = 'isOfficialAnnouncement'
              )
                template(#error)
                  .error-message(v-if = '!$v.notificationData.typeId.required') {{ $t('common:required') }}
                fi-select(
                  v-model.number = 'notificationData.typeId'
                  :options       = 'optionsFromClassifier("productNotificationTypes")'
                  :class         = '{ "is-invalid": $v.notificationData.typeId.$error }'
                  required
                  sm
                )

              .animated.fadeIn(v-if = 'isEmailChannel')
                fi-form-field(
                  :label      = '$t("attachment")'
                  :label-cols = 3
                )
                  v-loading(loader = 'classifiers:attachableTemplates:fetch')
                    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
                    fi-select(
                      v-model.number = 'notificationData.attachableTemplateId'
                      :options       = 'attachableTemplateOptions'
                      sm
                    )

              .animated.fadeIn(v-if = 'isAutomaticTask')
                fi-form-field(
                  :label      = '$t("actions")'
                  :label-cols = 3
                  :state      = '!$v.notificationData.actions.$error'
                )
                  template(#error)
                    .error-message(v-if = '!$v.notificationData.actions.required') {{ $t('actionsRequired') }}
                  fi-multiselect(
                    v-model  = 'selectedActionsModel'
                    :options = 'optionsFromClassifier("commentActionType")'
                    :class   = '{ "is-invalid": $v.notificationData.actions.$error }'
                    multiple
                    hide-selected
                  )

                fi-form-field(
                  :label      = '$t("category")'
                  :label-cols = 3
                )
                  fi-select(
                    v-model.number = 'notificationData.commentCategoryId'
                    :options       = 'optionsFromClassifier("commentCategory")'
                    required
                    sm
                  )

              .animated.fadeIn(v-if = 'isInDebt')
                fi-form-field(
                  :label      = '$t("gracePeriod")'
                  :label-cols = 3
                  :state      = '!$v.notificationData.penaltyGraceDays.$error'
                )
                  template(#error)
                    .error-message(v-if = '!$v.notificationData.productIds.required') {{ $t('common:required') }}
                  input.form-control.form-control-sm(
                    v-model.number = 'notificationData.penaltyGraceDays'
                    :class         = '{ "is-invalid": $v.notificationData.penaltyGraceDays.$error }'
                  )

              .animated.fadeIn(v-if = 'isApplicationRejected')
                fi-form-field(
                  :label      = '$t("closingReasons")'
                  :label-cols = 3
                  :state      = '!$v.notificationData.applicationClosingReasonIds.$error'
                )
                  template(#error)
                    .error-message(v-if = '!$v.notificationData.applicationClosingReasonIds.required') {{ $t('common:required') }}
                  fi-multiselect(v-model  = 'selectedClosingReasonModel'
                    :options      = 'mixingReasonsOptions'
                    :class        = '{ "is-invalid": $v.notificationData.applicationClosingReasonIds.$error }'
                    :loading      = '$vueLoading.isLoading("products:fetch")'
                    multiple
                    hide-selected
                  )

              fi-form-field(
                v-if        = 'isForProducts'
                :label      = '$t("products")'
                :label-cols = 3
                :state      = '!$v.notificationData.productIds.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.notificationData.productIds.required') {{ $t('common:required') }}
                fi-multiselect(v-model  = 'selectedProductsModel'
                  :options      = 'optionsFromProducts'
                  :class        = '{ "is-invalid": $v.notificationData.productIds.$error }'
                  :loading      = '$vueLoading.isLoading("products:fetch")'
                  multiple
                  hide-selected
                )

              fi-form-field.animated.fadeIn(
                v-if       = '!isAutomaticTask'
                :label     = '$t("segments")'
                label-cols = '3'
              )
                fi-multiselect(
                  v-model          = 'selectedSegmentsModel'
                  :options         = 'optionsFromClassifier("segments")'
                  track-by         = 'value'
                  label            = 'text'
                  multiple
                  hide-selected
                )

              fi-form-field(
                :label     = '$t("roles")'
                label-cols = '3'
              )
                fi-multiselect(
                  v-model          = 'selectedRolesModel'
                  :options         = 'optionsFromClassifier("roles")'
                  track-by         = 'value'
                  label            = 'text'
                  multiple
                  hide-selected
                )

              fi-form-field(
                :label      = '$t("feeName")'
                :label-cols = 3
              )
                fi-select(
                  v-model  = 'notificationData.feeType'
                  :options = 'feeOptions'
                  sm
                )
              button.btn.btn-primary.mb-3(
                type      = 'submit'
                :disabled = 'saving'
              ) {{ $t('common:save') }}
                i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
              | &nbsp;
              button.btn.btn-secondary.mb-3(
                :disabled      = 'saving'
                @click.prevent = 'onCancel'
              ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMultiselect from '@/components/FiMultiselect'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { AutomaticNotification } from '@/models'
import { validation, validators } from '@/validation'
import Template from '@/views/admin/templates/Template'
import unionBy from 'lodash/unionBy'
import { mapActions, mapGetters, mapState } from 'vuex'

import { notForProducts } from './triggers'

export default {
  name: 'automatic-notification',

  components: {
    Template,
    FiSwitch,
    FiMultiselect,
    FiSelect,
    FiFormField
  },

  mixins: [validation],

  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },

  i18nOptions: {},

  data: () => ({
    selectedProducts: null,
    selectedClosingReasons: null,
    selectedActions: null,
    selectedSegments: null,
    selectedRoles: null,
    notificationData: AutomaticNotification().data
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName', 'optionsFromClassifier']),
    ...mapGetters('templates', ['optionsFromTemplates', 'getAttachableTemplates']),
    ...mapGetters('products', ['optionsFromProducts', 'productById']),
    ...mapState('notifications', ['automaticNotification']),
    mixingReasonsOptions () {
      const reasonsForClosingApp = this.optionsFromClassifier('privateReasonsForClosingApp').map(el => ({
        text: `Private - ${el.text}`,
        value: el.value
      }))
      const reasonsForClosingBusiness = reasonsForClosingApp.concat(this.optionsFromClassifier('businessReasonsForClosingApp').map(el => ({
        text: `Business - ${el.text}`,
        value: el.value
      })))
      return reasonsForClosingBusiness
    },
    isNew () {
      return !this.id
    },
    isForProducts () {
      if (this.notificationData.triggerId && Object.keys(notForProducts).filter((el) => Number(el) === this.notificationData.triggerId).length) {
        return false
      }
      return true
    },
    header () {
      if (this.isNew) {
        return this.$t('newTitle')
      } else {
        return this.$t('editTitle', { id: this.id })
      }
    },
    saving () {
      return this.$vueLoading.isLoading(this.isNew ? 'notification:automatic:save' : `notification:automatic:${this.id}:save`)
    },
    selectedProductsModel: {
      get () {
        return this.selectedProducts
      },
      set (selected) {
        this.selectedProducts = selected
        this.notificationData.productIds = selected?.map(item => item.value)
      }
    },
    selectedClosingReasonModel: {
      get () {
        return this.selectedClosingReasons
      },
      set (selected) {
        this.selectedClosingReasons = selected
        this.notificationData.applicationClosingReasonIds = selected?.map(item => item.value)
      }
    },
    selectedActionsModel: {
      get () {
        return this.selectedActions
      },
      set (selected) {
        this.selectedActions = selected
        this.notificationData.actions = selected?.map(({ value }) => ({ type: value }))
      }
    },
    selectedSegmentsModel: {
      get () {
        return this.selectedSegments
      },
      set (selected) {
        this.selectedSegments = selected
        this.notificationData.segmentIds = selected?.map(({ value }) => value)
      }
    },
    selectedRolesModel: {
      get () {
        return this.selectedRoles
      },
      set (selected) {
        this.selectedRoles = selected
        this.notificationData.roleTypeIds = selected?.map(({ value }) => value)
      }
    },
    loading () {
      return [`notification:automatic:${this.id}:fetch`, 'products:fetch', 'templates:fetch']
    },
    feeOptions () {
      return this.notificationData?.productIds?.reduce((res, id) => {
        const product = this.productById(id)
        return unionBy(
          res,
          product.fees?.map(
            ({ feeType: { code, classifier } }) => (
              {
                value: code,
                text: this.classifierByName(classifier, code)?.human
              })
          ),
          'value'
        )
      }, [])
    },
    isAutomaticTask () {
      return this.classifierById('productNotificationTypes', this.notificationData.typeId).name === 'AUTOMATIC_TASK'
    },
    isInDebt () {
      return /IN_DEBT/i.test(this.selectedTrigger.code)
    },
    isApplicationRejected () {
      return (this.selectedTrigger.code === 'APPLICATION_REJECTED')
    },
    isEmailChannel () {
      return /EMAIL/i.test(this.classifierById('productNotificationTypes', this.notificationData.typeId).code)
    },
    attachableTemplateOptions () {
      return this.getAttachableTemplates
        .map(({ id, templateName }) => ({ value: id, text: templateName }))
    },
    selectedTrigger () {
      return this.classifierById('automaticNotificationTriggerType', this.notificationData.triggerId)
    },
    isDaysAfter () {
      const allowedTriggers = ['IN_DEBT']
      return allowedTriggers.includes(this.selectedTrigger.code)
    },
    isDaysBefore () {
      const allowedTriggers = ['PAYMENT_SCHEDULE_DAY', 'CONTRACT_DATE']
      return allowedTriggers.includes(this.selectedTrigger.code)
    },
    isDaysDisabled () {
      const allowedTriggers = [
        'APPLICATION_INSERT',
        'APPLICATION_REJECTED',
        'APPLICATION_REJECTED_DEBTS',
        'CONTRACT_PREPARED',
        'APPLICATION_ERROR',
        'PAYMENT_SUCCESS',
        'PAYMENT_FAILED',
        'CONTRACT_IS_ACTIVATED'
      ]
      return allowedTriggers.includes(this.selectedTrigger.code)
    },
    isOfficialAnnouncement () {
      return /AMETLIKUD_TEADAANDED/i.test(this.selectedTrigger.code)
    }
  },

  watch: {
    selectedTrigger () {
      if (this.isOfficialAnnouncement) {
        this.notificationData.typeId = this.classifierByName('productNotificationTypes', 'AUTOMATIC_TASK')?.id
      } else {
        this.notificationData.typeId = this.automaticNotification?.typeId
      }

      if (this.isDaysDisabled) {
        this.notificationData.triggerDays = 0
      }
    }
  },

  validations () {
    return {
      notificationData: {
        ...AutomaticNotification().validation,
        actions: {
          required: validators.requiredIf(function () {
            return this.isAutomaticTask
          }),
          $each: {}
        },
        productIds: {
          required: validators.requiredIf(function () {
            return this.isForProducts
          })
        }
      }
    }
  },

  async created () {
    await this.loadProducts({ group: this.optionsFromClassifier('productGroups', true).map(({ value }) => value) })
    this.loadTemplates()
    if (!this.isNew) {
      await this.loadAutomaticNotification({ notificationId: this.id })
      this.notificationData = AutomaticNotification(this.automaticNotification).data
      this.selectedProductsModel = this.automaticNotification.productIds?.map(id => ({
        value: id,
        text: this.productById(id).name
      }))
      this.selectedClosingReasonModel = this.automaticNotification.applicationClosingReasonIds?.map(id => ({
        value: id,
        text: `Private - ${this.classifierById('privateReasonsForClosingApp', id).human}`
      })).filter(el => el.text !== 'Private - undefined')
      const selectedClosingReasonBusiness = this.automaticNotification.applicationClosingReasonIds?.map(id => ({
        value: id,
        text: `Business - ${this.classifierById('businessReasonsForClosingApp', id).human}`
      })).filter(el => el.text !== 'Business - undefined')
      this.selectedClosingReasonModel = this.selectedClosingReasonModel.concat(selectedClosingReasonBusiness)
      this.selectedActionsModel = this.automaticNotification.actions?.map(({ type }) => {
        const action = this.classifierByName(type.classifier, type.code)
        return { value: action?.code, text: action?.human }
      })
      this.selectedSegmentsModel = this.automaticNotification.segmentIds?.map(id => ({
        value: id,
        text: this.classifierById('segments', id).human
      }))
      this.selectedRolesModel = this.automaticNotification.roleTypeIds?.map(id => ({
        value: id,
        text: this.classifierById('roles', id).human
      }))
    }
  },

  methods: {
    ...mapActions('notifications', ['createAutomaticNotification', 'loadAutomaticNotification', 'updateAutomaticNotification']),
    ...mapActions('products', ['loadProducts']),
    ...mapActions('templates', ['loadTemplates']),
    ...mapActions('classifiers', ['loadAttachableTemplates']),
    onCancel () {
      this.$router.push({ name: 'AutomaticNotificationList' })
    },
    async onSubmit () {
      if (this.validate()) {
        if (this.isNew) {
          await this.createAutomaticNotification({ notificationData: this.notificationData })
          this.$toasted.success(this.$t('successCreate'))
        } else {
          await this.updateAutomaticNotification({ notificationId: this.id, notificationData: this.notificationData })
          this.$toasted.success(this.$t('successEdit'))
        }
        this.$router.push({ name: 'AutomaticNotificationList' })
      }
    }
  }

}
</script>


<i18n>
en:
  newTitle:      "New automatic notification"
  editTitle:     "Edit automatic notification #{{ id }}"
  active:        "Active"
  template:      "Template"
  days:          "Days"
  channel:       "Channel"
  actions:       "Actions"
  category:      "Category"
  trigger:       "Trigger"
  gracePeriod:   "Penalty grace period"
  products:      "Products"
  segments:      "Segments"
  roles:         "Roles"
  feeName:       "Fee name"
  attachment:    "Attachment template"
  successCreate: "Automatic notification successfully created"
  successEdit:   "Automatic notification changes successfully saved"
  daysAfterNotice: "Days taken into account after trigger event occurred. For example contract status changed to Collector status on 01.01.2022, trigger days as 3. Result: notification sent on 04.01.2022."
  daysBeforeNotice: "Days taken into account before trigger event occurred. For example contract end date on 15.01.2022, trigger days as 5. Result: notification sent on 10.01.2022."
  actionsRequired: "At least one action is required for Automatic notification"
  closingReasons: "Closing reasons"
et:
  newTitle:      "New automatic notification"
  editTitle:     "Edit automatic notification #{{ id }}"
  active:        "Active"
  template:      "Template"
  days:          "Days"
  channel:       "Channel"
  actions:       "Actions"
  category:      "Category"
  trigger:       "Trigger"
  gracePeriod:   "Penalty grace period"
  products:      "Products"
  segments:      "Segments"
  roles:         "Roles"
  feeName:       "Fee name"
  attachment:    "Attachment template"
  successCreate: "Automatic notification successfully created"
  successEdit:   "Automatic notification changes successfully saved"
  daysAfterNotice: "Days taken into account after trigger event occurred. For example contract status changed to Collector status on 01.01.2022, trigger days as 3. Result: notification sent on 04.01.2022."
  daysBeforeNotice: "Days taken into account before trigger event occurred. For example contract end date on 15.01.2022, trigger days as 5. Result: notification sent on 10.01.2022."
  actionsRequired: "At least one action is required for Automatic notification"
  closingReasons: "Closing reasons"
ru:
  newTitle:      "New automatic notification"
  editTitle:     "Edit automatic notification #{{ id }}"
  active:        "Active"
  template:      "Template"
  days:          "Days"
  channel:       "Channel"
  actions:       "Actions"
  category:      "Category"
  trigger:       "Trigger"
  gracePeriod:   "Penalty grace period"
  products:      "Products"
  segments:      "Segments"
  roles:         "Roles"
  feeName:       "Fee name"
  attachment:    "Attachment template"
  successCreate: "Automatic notification successfully created"
  successEdit:   "Automatic notification changes successfully saved"
  daysAfterNotice: "Days taken into account after trigger event occurred. For example contract status changed to Collector status on 01.01.2022, trigger days as 3. Result: notification sent on 04.01.2022."
  daysBeforeNotice: "Days taken into account before trigger event occurred. For example contract end date on 15.01.2022, trigger days as 5. Result: notification sent on 10.01.2022."
  actionsRequired: "At least one action is required for Automatic notification"
  closingReasons: "Closing reasons"
</i18n>
